<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Notifikasi</p>
          </v-card-actions>
          <v-divider />
          <v-row>
            <v-col
              cols="12"
              offset="9"
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              class="px-3"
            >
              <v-card-actions>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  placeholder="Cari"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="search"
                ></v-text-field>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="notifications"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.subject`]="{ item }">
              <span class="hover-primary" @click="goToDetail(item.type)">{{
                item.subject
              }}</span>
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Notifikasi tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NotificationService from "@/services/resources/notification.service";
const ContentNotFound = () => import("@/components/Content/NotFound");
import { NOTIFICATION_TYPE } from "@/common/constant";

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      loading: false,
      search: null,
      headers: [
        {
          text: "Judul",
          value: "subject",
          sortable: false,
          align: "left"
        },
        {
          text: "Isi",
          value: "description",
          sortable: false,
          align: "left"
        },
        {
          text: "Tanggal",
          value: "notification_date",
          sortable: false,
          align: "center"
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "center"
        }
      ],
      notifications: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40]
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListNotification);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListNotification);
    }
  },
  computed: {
    paginationProperty() {
      return [this.search].join();
    }
  },
  methods: {
    goToDetail(type) {
      this.getToPage(NOTIFICATION_TYPE[type]);
    },
    // Service
    async getListNotification() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await NotificationService.getList({
          filter: {
            search: this.search
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let notifications = list;
              notifications.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.notifications = notifications;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
